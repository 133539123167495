import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Sorry for the inconvenience but we’re performing some maintenance at
          the moment
        </p>
      </header>
    </div>
  );
}

export default App;
